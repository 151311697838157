export default {
  planningMapView: {
    baseMap: "Bản đồ nền",
    toolsMap: "Công cụ bản đồ",
    googleMap: "Bản đồ Google",
    satelliteMap: "Bản đồ vệ tinh",
    notUseBaseMap: "Không dùng bản đồ nền",
    mapToolsPanel: {
      viewObjectInfomation: "Xem thông tin đối tượng",
      toggleFullScreen: "Mở rộng/Thu nhỏ",
      zoomIn: "Phóng to bản đồ",
      zoomOut: "Thu nhỏ bản đồ",
      filterInfomation: "Lọc thông tin",
      mapLegend: "Chú giải bản đồ",
      selectAnObject: "Chọn một đối tượng",
      selectMultiObject: "Chọn nhiều đối tượng",
      measuareArea: "Đo diện tích",
      measuareDistance: "Đo khoảng cách",
      moveViewport: "Di chuyển khung nhìn",
      printTheMap: "In bản đồ",
      myPosition: "Xác định vị trí của bạn",
      exportMap: "Xuất bản đồ",
      compare: "So sánh",
      locationMarker: "Đánh dấu vị trí",
    },
    filterInfomationPopup: {
      headTitle: "Tìm kiếm đối tượng",
      labelSelectSearch: "Chọn đối tượng tìm kiếm",
      searchInLine: "Tìm kiếm trên đường",
      searchInArea: "Tìm kiếm trong vùng",
      searchInCircle: "TÌm kiếm trong đường tròn",
      searchInSquare: "Tìm kiếm trong hình chữ nhật",
      searchWithAttribute: "Theo thuộc tính",
      search: "Tìm kiếm",
      delete: "Xóa",
      close: "Đóng",
    },
  },
  planningMapView_guideToUse: "Hướng dẫn sử dụng",
  planningMapView_guideToUse_category: "Danh mục hướng dẫn",
  planningMapView_map: "Bản đồ",
  planningMapView_infomation: "Thông tin",
  planningMapView_relatedPlanning: "Quy hoạch liên quan",
  planningMapView_result: "Kết quả",
  header_homePage: "Trang chủ",
  header_pleaseConsultTheCommunity: "Xin ý kiến cộng đồng",
  header_lookUpPlanning: "Tra cứu quy hoạch",
  header_news: "Tin tức",
  header_planningAnnouncement: "Công bố quy hoạch",
  header_recordsManager: "Quản lý hồ sơ",
  header_Contact: "Liên hệ",
  header_generalPlanning: "Quy hoạch chung",
  header_syntheticPlanning: "Tổng hợp quy hoạch",
  header_subdivisionPlanning: "Quy hoạch phân khu",
  header_detailedPlanning: "Quy hoạch chi tiết",
  header_specializedPlanning: "Quy hoạch chuyên ngành",
  header_administration: "Quản trị",
  header_logout: "Đăng xuất",
  header_logIn: "Đăng Nhập",
  header_account: "Tài khoản",
  header_title: "GIS Tiên Yên",
  footer_titleName: "SỞ XÂY DỰNG TỈNH CẦN THƠ",
    footer_email: "sxd@cantho.gov.vn",
    footer_email_to: "sxd@cantho.gov.vn",
    footer_address: "Đường Phan Bội Châu, Phường Tân Thịnh, Thành phố Hoà Bình, tỉnh Hoà Bình",
    footer_hotline: "0218 3.854.206",
    footer_hotline_tel: "0218 3.854.206",
    footer_fax: "0218 3.881.433",
    footer_website: "http://soxaydung.cantho.gov.vn/",
    footer_website_to: "http://soxaydung.cantho.gov.vn/", 
    contact_map_center_lat: 20.827501724727288,
    contact_map_center_lng: 105.34172903977286,
    contact_map_marker_lat: 20.827501724727288,
    contact_map_marker_lng: 105.34172903977286,
    contact_map_url: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3729.109827074168!2d105.33955645060571!3d20.827271086039683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31346ba14835479b%3A0xa89e4a4026ede5ab!2zU-G7nyBYw6J5IGThu7FuZyB04buJbmggSMOyYSBCw6xuaA!5e0!3m2!1svi!2s!4v1634037573815!5m2!1svi!2s',
};
